import React from 'react'
import Header from '../Header-Footer/Header'

const LavelFour = () => {
  return (
    <>
     <div class='dashboard-app'>
                <Header />
                <div class='dashboard-content'>
                    <div class='container'>
                        <section class="level_sec1">
                            <div class="lavel_sel_head d-flex">
                                <div class="lavel_sel_head_box1 align-self">
                                    <h5 class="lavel_head_title mb-0 w">X4</h5>
                                </div>
                                <div class="lavel_sel_head_box2 align-self">
                                    <h5 class="lavel_head_up mb-0 w">Upline <span>ID 1</span></h5>
                                </div>
                            </div>

                            <div id="carouselExampleControlsNoTouching" class="carousel slide carousel_sel" data-bs-touch="false" data-bs-interval="false">

                                <div class="carousel-inner carousel-inner-1">
                                    <div class="carousel-item carousel-item-1 active">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>
                                            <div className='lavel-box-main mt-4 mb-4'>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={'5335'} />
                                                    </div>
                                                </div>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={'5335'} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-2">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>2</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>
                                            <div className='lavel-box-main mt-5 mb-5'>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                    </div>
                                                    <div className='show-label-list mt-2'>
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                    </div>
                                                </div>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                    </div>
                                                    <div className='show-label-list mt-2'>
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                        <input className='btn val-input-btn-12' type='button' value={'5335'} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="carousel-item carousel-item-3">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>3</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>
                                            <div className='c-overflow-scroll'>
                                                <div className='lavel-box-main c-width mt-5 mb-5'>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                            <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''><input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                </div>
                                                            </div>
                                                            <div className=''><input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                            <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''><input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                </div>
                                                            </div>
                                                            <div className=''><input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                    <input className='btn val-input-btn-2' type='button' value={'5335'} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-4">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>4</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>
                                            <div className='c-overflow-scroll'>
                                                <div className='lavel-box-main c-width-1 mt-2 mb-2'>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                            <input className='btn' type='button' value={'5335'} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                                <input className='btn' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <input className='btn' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                            <input className='btn' type='button' value={'5335'} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                                <input className='btn' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <input className='btn' type='button' value={'5335'} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                    <div><input className='btn' type='button' value={'5335'} />
                                                                        <div className='show-label-list mt-2'>
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                            <input className='btn' type='button' value={'5335'} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                            </div>


                                        </div>
                                    </div>


                                </div>
                                <button class="carousel-control-prev carousel-control-prev-1" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                                    <i class="fa-solid fa-arrow-left w"></i>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next carousel-control-next-1" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                                    <i class="fa-solid fa-arrow-right w"></i>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </section>
                        <section>
                            <div class="partners-table-box table-responsive">
                                <div class="partners-table ">
                                    <table class="table-income">
                                        <tbody><tr class="table-row">
                                            <th class="table-heading w"> Type</th>
                                            <th class="table-heading w">Date</th>
                                            <th class="table-heading w">ID</th>
                                            <th class="table-heading w">Program</th>
                                            <th class="table-heading w">Level</th>
                                            <th class="table-heading w">Wallet</th>
                                            <th class="table-heading w">USDT / BNB profit</th>
                                        </tr>
                                            <tr class="table-row">
                                                <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td>
                                                <td class="table-data">20.10.2022 09:49</td>
                                                <td class="table-data"><span class="user-id m-0">ID 1668898</span></td>
                                                <td class="table-data">maxQore</td>
                                                <td class="table-data">1</td>
                                                <td class="table-data">0x31dA2...6C7d9                                </td>
                                                <td class="table-data g">recycle</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td>
                                                <td class="table-data">20.10.2022 09:49</td>
                                                <td class="table-data"><span class="user-id m-0">ID 1668898</span></td>
                                                <td class="table-data">maxQore</td>
                                                <td class="table-data">1</td>
                                                <td class="table-data">0x31dA2...6C7d9                                </td>
                                                <td class="table-data g">recycle</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td>
                                                <td class="table-data">20.10.2022 09:49</td>
                                                <td class="table-data"><span class="user-id m-0">ID 1668898</span></td>
                                                <td class="table-data">maxQore</td>
                                                <td class="table-data">1</td>
                                                <td class="table-data">0x31dA2...6C7d9                                </td>
                                                <td class="table-data g">recycle</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td>
                                                <td class="table-data">20.10.2022 09:49</td>
                                                <td class="table-data"><span class="user-id m-0">ID 1668898</span></td>
                                                <td class="table-data">maxQore</td>
                                                <td class="table-data">1</td>
                                                <td class="table-data">0x31dA2...6C7d9                                </td>
                                                <td class="table-data g">recycle</td>
                                            </tr>
                                            <tr class="table-row">
                                                <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td>
                                                <td class="table-data">20.10.2022 09:49</td>
                                                <td class="table-data"><span class="user-id m-0">ID 1668898</span></td>
                                                <td class="table-data">maxQore</td>
                                                <td class="table-data">1</td>
                                                <td class="table-data">0x31dA2...6C7d9                                </td>
                                                <td class="table-data g">recycle</td>
                                            </tr>
                                        </tbody></table>

                                </div>
                                <button class="see-more" href="#">See More</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    
    </>
  )
}

export default LavelFour