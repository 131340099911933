import React from "react";
import jsPDF from "jspdf";
import a from '../assets/img/pdf/pdf1.jpg';
import b from '../assets/img/pdf/pdf2.jpg';
import c from '../assets/img/pdf/pdf3.jpg';
import d from '../assets/img/pdf/pdf4.jpg';
import e from '../assets/img/pdf/pdf5.jpg';
import f from '../assets/img/pdf/pdf6.jpg';
import g from '../assets/img/pdf/pdf7.jpg';
import h from '../assets/img/pdf/pdf8.jpg';
import i from '../assets/img/pdf/pdf9.jpg';
import k from '../assets/img/pdf/pdf10.jpg';

import { NavLink } from "react-router-dom";
const Document = () => {
    const downloadPdf = () => {
        const doc = new jsPDF('p', 'mm', 'a4'); // A4 page dimensions

        // Define images
        const images = [a, b, c, d, e, f, g, h, i, k];

        // Calculate image dimensions to fit within A4 page
        const imageSize = { width: 190, height: 257 }; // Adjust dimensions as needed

        images.forEach((img, index) => {
            // Add page if not the first image
            if (index !== 0) {
                doc.addPage();
            }

            // Calculate position to center the image
            const x = (doc.internal.pageSize.width - imageSize.width) / 2;
            const y = (doc.internal.pageSize.height - imageSize.height) / 2;

            // Add image to PDF
            doc.addImage(img, 'JPEG', x, y, imageSize.width, imageSize.height);
        });

        doc.save("images.pdf");
    };
    return (
        <div className="">

            <div>
                <img className="w-100" src={a} alt="a" />
                <img className="w-100" src={b} alt="b" />
                <img className="w-100" src={c} alt="c" />
                <img className="w-100" src={d} alt="d" />
                <img className="w-100" src={e} alt="e" />
                <img className="w-100" src={f} alt="f" />
                <img className="w-100" src={g} alt="g" />
                <img className="w-100" src={h} alt="h" />
                <img className="w-100" src={i} alt="i" />
                <img className="w-100" src={k} alt="k" />
                
            </div>
            <div className="text-center py-3 d-flex f-btn-div">
                
                
                    <a className=" contact mukta-regular" > <span className="contact_bg" onClick={downloadPdf}> Download </span></a>
                
               
                    <NavLink className=" contact mukta-regular" to="/"> <span className="contact_bg"> Back </span></NavLink>
                
            </div>


        </div>
    )
}
export default Document;