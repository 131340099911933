import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Connect.css'
import logo from '../assets/img/logo.png'
import { NavLink } from "react-router-dom";
import Swal from 'sweetalert2';
import { Web3 } from 'web3';
import { TOKEN_ABI, USDT_TOKEN_ADDRESS, MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS, } from "../Imp"
import { useAppContext } from '../AppContext';
import toast from 'react-hot-toast';
import onchain from '../assets/img/on_chain.png'
import Footer from "../Header-Footer/Footer";
import site_logo from '../assets/img/logo.png';
import moment from "moment";
import axios from 'axios';
import qs from 'qs';



const Connectwallet = () => {
  const [walletAddress, setwalletAddress] = useState('')
  const [web3, setWeb3] = useState(null);
  const [balance, setBalance] = useState('');
  const [data, setData] = useState('')
  const { setUsdtBalance, setAddress, address } = useAppContext();
  const [userData, setUserData] = useState('')

  const navigate = useNavigate()






  // CORRECT CODE
  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    }

    getData();
    callData("0xFBc2DC59099Df95dc07353c602B70904c11d9707")
  }, []);


  const callData = (address) => {

    let data = qs.stringify({
    'address': address 
    });

    let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://roksa.io/Api/address_data.php',
    headers: { 
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    data : data
    };

    axios.request(config)
    .then((response) => {
    (JSON.stringify(response.data));
    console.log(response.data);
    setUserData(response.data);
    })
    .catch((error) => {
    console.log(error);
    });

}

  const getData = async () => {
    try {
      const response = await fetch("https://roksa.io/Api/registerUsers.php", {
        method: 'GET',
        headers: {
          'Content-Type': "application/json"
        }
      }
      )
      const data = await response.json()
      setData(data.users)
    } catch (error) {
      console.log(error);
    }

  }


  function convertToRelativeTime(timestampInSeconds) {
    const timestampInMilliseconds = timestampInSeconds * 1000;
    const relativeTime = moment(timestampInMilliseconds).fromNow();
    return relativeTime;
  }

  const blockchainExp=(hash)=>{
    const exploreURL=`https://bscscan.com/tx/${hash}`
    window.open(exploreURL,'_blank')
}

  const copyContractAdd = (text) => {
    const fullText = text;
    const textarea = document.createElement('textarea');
    textarea.value = fullText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success("copy to clipboard")

}
  //CONNECT WALLET BUTTON

  const connectToMetamask = async () => {
    console.log("Connect Wallet clicked");

    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        console.log("MetaMask connected successfully");

        const accounts = await web3.eth.getAccounts();
        const balanceInWei = await web3.eth.getBalance(accounts[0]);
        const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

        setAddress(accounts[0])
        // localStorage.setItem("userAddress",accounts[0])
        setBalance(balanceInEther)

        // Fetching USDT balance
        const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
        const usdtBalanceInWei = await usdtContract.methods.balanceOf(accounts[0]).call();
        const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
        setUsdtBalance(usdtBalanceInEther)

        console.log('MLM_CONTRACT_ABI ', MLM_CONTRACT_ABI
          , 'MLM_CONTRACT_ADDRESS',
          MLM_CONTRACT_ADDRESS

        )


        //Check register or not 
        try {



          const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS)
          const ifRegistered = await mlmContract.methods.users(accounts[0]).call()
          console.log("ifRegistered", ifRegistered.isExist);
          if (ifRegistered.isExist) {
            navigate("/welcomeuser");
          } else {
            navigate("/register");
          }

        } catch (err) {
          console.log(" err    ifRegistered", err);
        }

      } catch (error) {
        console.error('User denied account access', error);
      }
    } else {
      console.log("MetaMask not found");
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "Please download MetaMask to use this feature!",
        footer: '<a href="https://metamask.io/download/">Download MetaMask</a>',

      });
    }
  };

  //SEARCH BY WALLLET ADDRESS
  const searchByWalletAddress = async (event) => {
    event.preventDefault();

    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum)
      setWeb3(web3Instance)
    }
    try {
      const account = walletAddress;
      console.log("account:", account);
      const balanceInWei = await web3.eth.getBalance(account);
      const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

      setAddress(account)
      setBalance(balanceInEther)

      // Fetching USDT balance
      const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
      const usdtBalanceInWei = await usdtContract.methods.balanceOf(account).call();
      const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
      setUsdtBalance(usdtBalanceInEther)

      const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS)
      const ifRegistered = await mlmContract.methods.users(account).call()
      console.log(ifRegistered.isExist);
      if (ifRegistered.isExist) {
        navigate("/welcomeuser");
      }
      // }else{
      //     toast.error("Invalid Wallet Address")
      // }
    } catch (error) {
      toast.error("Invalid Wallet Address")

      console.error("error", error);
    }
  }

  const handleInputChange = (event) => {
    setwalletAddress(event.target.value)
  }

  //DEMO WALLET 

  const demoWallet = async () => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum)
      setWeb3(web3Instance)
    }
    try {
      const account = "0xFBc2DC59099Df95dc07353c602B70904c11d9707";
      console.log(account);
      const balanceInWei = await web3.eth.getBalance(account);
      const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

      setAddress(account)
      setBalance(balanceInEther)

      // Fetching USDT balance
      const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
      const usdtBalanceInWei = await usdtContract.methods.balanceOf(account).call();
      const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
      setUsdtBalance(usdtBalanceInEther)

      const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS)
      const ifRegistered = await mlmContract.methods.users(account).call()
      console.log('ifRegistered ', ifRegistered);
      if (ifRegistered.isExist) {
        navigate("/welcomeuser");
      }

      // }else{
      //     toast.error("Invalid Wallet Address")
      // }
    } catch (error) {
      toast.error("Invalid Wallet Address")

      console.error("error", error);
    }
  }

  return (
    <>


      <div>
        <section className="sec01 bg-dark py-2" id="header01">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <a className="navbar-brand" ><img src={logo} alt="logo" className="logo" /></a>
              </div>
              <div className="col-6 text-end align-self">
              
                  <NavLink
                    className=" contact mukta-regular btn_connect "
                     onClick={connectToMetamask}
                  >
                    <span className="contact_bg">Connect wallet</span>
                  </NavLink>
            
              </div>
            </div>
          </div>
        </section>
        <section className="sec02">
          <div className="container">
            <div className="ani02">
              <div className="ani">
                <div className="contexts  cont-1">
                  <div className="box_01 conn-banner-content">
                    <h1 className="w banner_title poppins">Welcome to Roksa Web</h1>
                    <p className="w banner_text mukta-regular">Connect your wallet to start working. First time here? Watch a tutorial to learn more</p>
                    <div className="conn-btn-mt">

                      <button className="contact mukta-regular btn_connect b-btn-connect" onClick={connectToMetamask}><span className="contact_bg btn-span">Connect Wallet</span></button>
                    </div>


                  </div>
                </div>
                <div className="area">
                  <ul className="circles">
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                  </ul>
                </div>
              </div>
            </div>
          </div></section>
        <section className="sec03">
          <div className="container">
            <h2 className="partners-text w poppins">Account preview</h2>
            <p className="w banner_text mukta-regular">Look up any Roksa USDT member account in preview mode. Enter ID or USDT address to preview or click Demo to view a random account.        </p>
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-6">
                <div className="form_box">
                  <h5 className="partners-title w">Don’t know any ID?</h5>
                  <form>
                    <input className="form_input" type="text" placeholder="example: 89556" value={walletAddress} onChange={handleInputChange} />
                    <input type="submit" className="submit" defaultValue="Preview" onClick={searchByWalletAddress} />
                  </form>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div className="know">
                  <h5 className="partners-title w">Don’t know any ID?</h5>
                  <img className="partners4" src="./img/bnbUSDT.png" alt="" />
                  <a className="check_demo" onClick={demoWallet}>Check demo</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec04">
          <div className="container">
            <h2 className="partners-text w poppins roadmap_title"></h2>
            <div className="row conn-row">
              <div className="col-md-6 col-sm-12 col-12">
                <div className="wid">
                  <img src={onchain} alt="" />
                </div>

              </div>
              <div className="col-md-6 col-sm-12 col-12">
                <div className="wid-content">
                  <h5 className="about_sub w poppins"><i className="fa-solid fa-circle me-2 bl  conn-h5"></i> WHAT IS DECENTRALIZATION ? <i className="fa-solid fa-circle ms-2 bl"></i></h5>
                  <p>Decentralization refers to the distribution of power, authority, and decision-making across a network rather than being concentrated in a single entity or central authority. In decentralized systems, no single entity has complete control, ensuring a more democratic and resilient structure. This concept is widely applied in various fields, including technology, finance, and governance. Decentralized systems often utilize blockchain technology, where data is distributed across multiple nodes, enhancing security, transparency, and eliminating the need for intermediaries. This paradigm shift towards decentralization empowers individuals, fosters innovation, and mitigates the risks associated with centralized control.</p>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="sec04">
          <div className="container">
            <h2 className="partners-text w poppins">Account preview</h2>
            <div className="margin-top" />
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="cont-overflow-scroll">
                  <div className="table-recent">
                    {
                      data && data.slice(0, 12).map((item, index) => {
                        // const sliceAddress = item.address.slice(0, 16)
                        return (
                          <div className="d-flex part20 table-row">
                            <div className="icon-box1">
                              <div><i className="fa-solid fa-user user" /> <span className="joined">{(item.hash).slice(0,42)}</span>
                                {/* <span className="user-id">{item.id}</span> */}
                              </div>
                            </div>
                            <div className="align-self">
                              <h3 className="minutes"><i className="fa-solid fa-arrow-up-right-from-square w arrow-up-right"onClick={()=>blockchainExp(item.hash)} /> {convertToRelativeTime(item.time)}</h3>
                            </div>
                          </div>)
                      })}
                    <button className="see-more" href="#">See More</button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="table-recent members_received">
                  <h4 class="members-title">Members received                                         </h4>
                  <h3 class="x3_title w c-green">{userData.members_received}                                     </h3>
                  {/* <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                </div>
                <div class="table-recent mt-3">
                  <h4 class="members-title">Members Total</h4>
                  <h3 class="x3_title w c-green">{userData.members_total}</h3>
                  {/* <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                  {/* <div class="border-w"></div>
                  <h3 class="x3_title w">22 502.3186 BNB                                    </h3>
                  <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                </div>
                <div class="table-recent mt-3">
                  <h4 class="members-title">Roksa Contracts Address</h4>
                  <div class="border-w"></div>
                  <div class="d-flex part20">

                    <div class="fors2">
                      {/* <h4 class="x3-text w mb-0">0xb6c0A45d....bb145b5e57719 <i class="fa-regular fa-copy mx-2"></i> <i class="fa-solid fa-link"></i></h4> */}
                      <h4 class="x3-text w mb-0 c-green">0xFC745FA5....5eeeD86ef6615 <i class="fa-regular fa-copy mx-2" onClick={() => copyContractAdd(MLM_CONTRACT_ADDRESS)}></i> <i class="fa-solid fa-link" onClick={()=>blockchainExp(MLM_CONTRACT_ADDRESS)}></i></h4>

                    </div>
                  </div>
                  <div class="border-w"></div>
                  <div class="border-w"></div>
                  <h4 class="members-title"> Turnover, USDT                                    </h4>
                                        <h3 class="x3_title w c-green">{userData.turnover}</h3>
                  {/* <span class="g green-text_m">+ 22 568.2</span> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec04">
          <div className="container">
            <h2 className="partners-text w poppins text-center">Need help with using the platform?</h2>
            <p className="w banner_text mukta-regular text-center">Get qualified support from Roksa experts via online chat  </p>
            <div className="mt-5 text-center pb-3">
              <a className=" contact mukta-regular btn_connect" href="*"> <span className="contact_bg"> Contact support </span></a>
            </div>
          </div>
        </section>
      </div>
      <footer>
        <div className="container">
          <div className="top_icon_main_box">
            <div className="top_btn">
            </div>
            <div className="top_icon_box center">
              <div className="top_icon">
                <a href="#header01">
                  <i className="fa-solid fa-arrow-up w"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 mt-3">
              <div className="footer_col1">
                <a className="navbar-brand" href="./index.html"><img src={site_logo} alt="logo" className="footer-logo-img" /></a>

              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-b t-center pp-1 mt-3">
              {/* <h4 className="footer_title w poppins">USEFUL LINKS</h4> */}
              <ul className="footer_list">
                <li className="footer_list_item"><a href="#" className="footer_list_link">Contact us</a></li>
                <li className="footer_list_item"><a href="#" className="footer_list_link">Explore</a></li>
                <li className="footer_list_item"><a href="#" className="footer_list_link">Document</a></li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-b pp-1 t-center mt-3">
              {/* <h4 className="footer_title w poppins">COMMUNITY</h4> */}
              <ul className="footer_list">
                <li className="footer_list_item"><a href="#" className="footer_list_link">About</a></li>
                <li className="footer_list_item"><a href="#" className="footer_list_link">Process</a></li>
                <li className="footer_list_item"><a href="#" className="footer_list_link">Partners</a></li>

              </ul>

            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 align-b  t-center">
              {/* <h4 className="footer_title w poppins">SUBSCRIBE NEWSLETTER</h4> */}
              <p className="footer_text w mukta-regular mt-4">Roksa Web is a pioneering decentralized networking platform leveraging smart contracts on the Binance Smart Chain              </p>

            </div>
          </div>
        </div>
      </footer>

    </>
  )
}
export default Connectwallet;


















