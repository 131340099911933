import React,{useState,useEffect} from 'react'
import Header from '../Header-Footer/Header'
import { useAppContext } from '../AppContext';
// var request = require('request');
import axios from 'axios';
import qs from 'qs';

const LavelTwo = () => {
    const [data, setData] = useState('')
    const {address } = useAppContext();


    useEffect(() => {
        callData("2",address);
    }, [])
    
const callData = (pool,address) => {

            let data = qs.stringify({
                'pool': pool,
                'address': address 
            });

            let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://roksa.io/Api/pool_members.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
            };

            axios.request(config)
            .then((response) => {
            (JSON.stringify(response.data));
            setData(response.data);
            })
            .catch((error) => {
            console.log(error);
            });

    }
    return (
        <>
            <div class='dashboard-app'>
                <Header />
                <div class='dashboard-content'>
                    <div class='container'>
                        <section class="level_sec1">
                            <div class="lavel_sel_head d-flex">
                                <div class="lavel_sel_head_box1 align-self">
                                    <h5 class="lavel_head_title mb-0 w">X2</h5>
                                </div>
                                <div class="lavel_sel_head_box2 align-self">
                                    <h5 class="lavel_head_up mb-0 w">Upline <span>{address}</span></h5>
                                </div>
                            </div>

                            <div id="carouselExampleControlsNoTouching" class="carousel slide carousel_sel" data-bs-touch="false" data-bs-interval="false">

                                <div class="carousel-inner carousel-inner-1">
                                    <div class="carousel-item carousel-item-1 active">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>{address.slice(0,16)}</span>
                                                </li>
                                            </div>
                                            <div className='lavel-box-main mt-4 mb-4'>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[0]?.value_2) ? (data.members[0]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                </div>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[1]?.value_2) ? (data.members[1]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                {/* <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li> */}
                                            </div>


                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-2">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>2</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>{address.slice(0,16)}</span>
                                                </li>
                                            </div>
                                            <div className='lavel-box-main mt-5 mb-5'>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[0]?.value_2) ? (data.members[0]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                    <div className='show-label-list mt-2'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[2]?.value_2) ? (data.members[2]?.value_2).slice(-6) : ''}`} />
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[3]?.value_2) ? (data.members[3]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                </div>
                                                <div className='show-label-box'>
                                                    <div className='show-label-list'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[1]?.value_2) ? (data.members[1]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                    <div className='show-label-list mt-2'>
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[4]?.value_2) ? (data.members[4]?.value_2).slice(-6) : ''}`} />
                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[5]?.value_2) ? (data.members[5]?.value_2).slice(-6) : ''}`} />
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                {/* <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li> */}
                                            </div>


                                        </div>
                                    </div>

                                    <div class="carousel-item carousel-item-3">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>3</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>{address.slice(0,16)}</span>
                                                </li>
                                            </div>
                                            <div className='c-overflow-scroll'>
                                                <div className='lavel-box-main c-width mt-5 mb-5'>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[0]?.value_2) ? (data.members[0]?.value_2).slice(-6) : ''}`} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[2]?.value_2) ? (data.members[2]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[6]?.value_2) ? (data.members[6]?.value_2).slice(-6) : ''}`} />
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[7]?.value_2) ? (data.members[7]?.value_2).slice(-6) : ''}`} />
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[3]?.value_2) ? (data.members[3]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[8]?.value_2) ? (data.members[8]?.value_2).slice(-6) : ''}`} />
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[9]?.value_2) ? (data.members[9]?.value_2).slice(-6) : ''}`} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[1]?.value_2) ? (data.members[1]?.value_2).slice(-6) : ''}`} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[4]?.value_2) ? (data.members[4]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[10]?.value_2) ? (data.members[10]?.value_2).slice(-6) : ''}`} />
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[11]?.value_2) ? (data.members[11]?.value_2).slice(-6) : ''}`} />
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[5]?.value_2) ? (data.members[5]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[12]?.value_2) ? (data.members[12]?.value_2).slice(-6) : ''}`} />
                                                                <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[13]?.value_2) ? (data.members[13]?.value_2).slice(-6) : ''}`} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                {/* <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li> */}
                                            </div>


                                        </div>
                                    </div>
                                    <div class="carousel-item carousel-item-4">
                                        <div class="carousel_box">
                                            <div className='carousel-box-header'>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Lvl</span>
                                                    <span className='lulvalue'>4</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Address</span>
                                                    <span className='lulvalue'>{address.slice(0,16)}</span>
                                                </li>
                                            </div>
                                            <div className='c-overflow-scroll'>
                                                <div className='lavel-box-main c-width-1 mt-2 mb-2'>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[0]?.value_2) ? (data.members[0]?.value_2).slice(-6) : ''}`} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[2]?.value_2) ? (data.members[2]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[6]?.value_2) ? (data.members[6]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[14]?.value_2) ? (data.members[14]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[15]?.value_2) ? (data.members[15]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[7]?.value_2) ? (data.members[7]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[16]?.value_2) ? (data.members[16]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[17]?.value_2) ? (data.members[17]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[3]?.value_2) ? (data.members[3]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[8]?.value_2) ? (data.members[8]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[18]?.value_2) ? (data.members[18]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[19]?.value_2) ? (data.members[19]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[9]?.value_2) ? (data.members[9]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[20]?.value_2) ? (data.members[20]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[21]?.value_2) ? (data.members[21]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='show-label-box'>
                                                        <div className='show-label-list'>
                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[1]?.value_2) ? (data.members[1]?.value_2).slice(-6) : ''}`} />
                                                        </div>
                                                        <div className='show-label-list mt-2'>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[4]?.value_2) ? (data.members[4]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[10]?.value_2) ? (data.members[10]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[22]?.value_2) ? (data.members[22]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[23]?.value_2) ? (data.members[23]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[11]?.value_2) ? (data.members[11]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[24]?.value_2) ? (data.members[24]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[25]?.value_2) ? (data.members[25]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                            <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[5]?.value_2) ? (data.members[5]?.value_2).slice(-6) : ''}`} />
                                                                <div className='show-label-list mt-2'>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[12]?.value_2) ? (data.members[12]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[26]?.value_2) ? (data.members[26]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[27]?.value_2) ? (data.members[27]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                    <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[13]?.value_2) ? (data.members[13]?.value_2).slice(-6) : ''}`} />
                                                                        <div className='show-label-list mt-2'>
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[28]?.value_2) ? (data.members[28]?.value_2).slice(-6) : ''}`} />
                                                                        <input className='btn val-input-btn-11' type='button' value={`....${(data.members && data.members[29]?.value_2) ? (data.members[29]?.value_2).slice(-6) : ''}`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='carousel-box-header mt-5'>
                                                {/* <li>
                                                    <span className='carousel-box-header-lvl'>Partners</span>
                                                    <span className='lulvalue'>1</span>
                                                </li>
                                                <li>
                                                    <span className='carousel-box-header-lvl'>Total </span>
                                                    <span className='lulvalue'>1</span>
                                                </li> */}
                                            </div>


                                        </div>
                                    </div>


                                </div>
                                <button class="carousel-control-prev carousel-control-prev-1" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                                    <i class="fa-solid fa-arrow-left w"></i>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next carousel-control-next-1" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                                    <i class="fa-solid fa-arrow-right w"></i>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </section>
                        <section>
                            <div class="partners-table-box table-responsive">
                                <div class="partners-table ">
                                    <table class="table-income">
                                        <tbody><tr class="table-row">
                                            {/* <th class="table-heading w"> Type</th> */}
                                            <th class="table-heading w">Date</th>
                                            {/* <th class="table-heading w">ID</th> */}
                                            <th class="table-heading w">Pool</th>
                                            <th class="table-heading w">Level</th>
                                            <th class="table-heading w">Wallet</th>
                                            <th class="table-heading w">USDT / BNB profit</th>
                                        </tr>
                                        {
                                                data && data.members.map((item, index) => {
                                                    // let level = index < 2 ? 1 : 2; // Check index to determine the level
                                                    let level;
                                                    if (index < 2) {
                                                        level = 1;
                                                    } else if (index < 6) {
                                                        level = 2;
                                                    } else if(index<14) {
                                                        level = 3;
                                                    }else{
                                                        level=4
                                                    }

                                                    return (
                                            <tr class="table-row">
                                                {/* <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td> */}
                                                <td class="table-data">{item.created_at}</td>
                                                {/* <td class="table-data"><span class="user-id m-0">{item.id}</span></td> */}
                                                <td class="table-data">2</td>
                                                <td class="table-data">{level}</td>
                                                <td class="table-data">{item.value_2}                               </td>
                                                <td class="table-data g">{item.income}</td>
                                            </tr>
                                            )
})
}

                                        </tbody></table>

                                </div>
                                <button class="see-more" href="#">See More</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LavelTwo