import React from 'react'
import Logo from '../assets/img/2logo.png'
import { NavLink } from 'react-router-dom'

const Header = () => {


  const toggle = () => {
    var sidebar = document.getElementById('m-sidebar');
    sidebar.classList.toggle("new_sidebar");
  }
  const closeBtn = () => {
    var sidebar = document.getElementById('m-sidebar');
    sidebar.classList.remove("new_sidebar");
  }
  return (
    <>
      <div className='main-mobile-header'>

        <div className="header-row">
          <div className='m-logo'>
            <img src={Logo} alt="no logoimage" />
          </div>
          <div className='m-sidebar'>
            <span href="#" className="menu-toggle" onClick={toggle}><i className="fas fa-bars"></i></span>

            <div id="m-sidebar" className='mobile-sidebar '>
              <div className='mobile-sidebar-header'>
                <img src={Logo} alt="no logoimage" />
                <span className='close-btn-sidebar' onClick={closeBtn}><i class="fa-solid fa-xmark"></i></span>
              </div>
              <div className='navbar-menu'>
                <ul>
                  <li className='list list-active'>
                    <NavLink to="/Dashboard" className="list-link">Dashboard </NavLink>
                  </li>
                  <li className='list mt-2'>
                    <NavLink to="/partners" className="list-link"><span><i className="fas fa-file-upload"></i></span>Partners</NavLink>
                  </li>
                  <li className='list mt-2'>
                    <NavLink to="/income" className="list-link"><span><i className="fas fa-file-upload"></i></span>Income</NavLink>
                  </li>
                  <li className='list mt-2'>
                    <NavLink to="/document" className="list-link"><span><i className="fas fa-file-upload"></i></span>Documents</NavLink>
                  </li>

                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Header