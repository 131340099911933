import React from 'react'
import logo from "../assets/img/logo.png"
import { NavLink } from 'react-router-dom'

const Welcomeuser = () => {
  return (
    <>
      <div className='welcome-user-main'>
            <div className='welcome-row'>
                <div className='welcome-logo'>
                    <img src={logo} alt="w-img"/>
                </div>
                <div className='welcome-content'>
                   
                    <div className="box_01">
                    <h1 className="w banner_title poppins hh1 mb-5">Welcome to Roksa Web</h1>
                   
                  
                   
                      <NavLink className=" contact mukta-regular btn_connect mt-3 " to="/dashboard">
                        <span className="contact_bg">
                          Go TO Dashboard
                        </span>
                      </NavLink>
                  
                  </div>
                </div>
            </div> 
      </div>

    
    
    
    </>
  )
}

export default Welcomeuser