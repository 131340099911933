// RUNNING CODE 

import React, { useState, useEffect } from 'react';
import "./Registration.css"
import { Web3 } from 'web3'
import Swal from 'sweetalert2';
import { TOKEN_ABI, USDT_TOKEN_ADDRESS , MLM_CONTRACT_ABI , MLM_CONTRACT_ADDRESS } from "../Imp"
import { useAppContext } from '../AppContext';
import logo from "../assets/img/logo.png"
import about from "../assets/img/about_img01.png"
import { useNavigate ,useLocation} from 'react-router';
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner'; 


const Registration = () => {
  // const {sponsor}=useParams()
  const navigate=useNavigate();
  const [web3, setWeb3] = useState(null);
  const [balance, setBalance] = useState('');
  const { setUsdtBalance, setAddress, address } = useAppContext();
  const location=useLocation();
  const sponsor=new URLSearchParams(location.search).get('sponserid')
  const [sponsorId, setSponsorId] = useState(sponsor||'');
  const [gasEstimate, setGasEstimate] = useState(0);
  const [loading, setLoading] = useState(false); // Add loading state

  console.log("sponsor:",sponsor);

  useEffect(() => {
    const fetchData = async () => {

      if(sponsor !== undefined){
        setSponsorId(sponsor);
    }
     
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum);
        setWeb3(web3);

        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          console.log("MetaMask connected successfully");

          const accounts = await web3.eth.getAccounts();
          const balanceInWei = await web3.eth.getBalance(accounts[0]);
          const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

          setAddress(accounts[0]);
          setBalance(balanceInEther);
          // Fetching USDT balance
          const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
          const usdtBalanceInWei = await usdtContract.methods.balanceOf(accounts[0]).call();
          const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
          setUsdtBalance(usdtBalanceInEther)


        

        } catch (error) {
          console.error('User denied account access', error);
        }
      } else {
        console.log("MetaMask not found");
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: "Please download MetaMask to use this feature!",
          footer: '<a href="https://metamask.io/download/">Download MetaMask</a>',
          customClass: {
            popup: 'custom-swal-popup',
            icon: 'custom-swal-icon',
            title: 'custom-swal-title',
            text: 'custom-swal-text',
            confirmButton: 'custom-swal-confirm-button',
            cancelButton: 'custom-swal-cancel-button',
            footer: 'custom-swal-footer'
          }
        });
      }
    };

    fetchData();
  }, [navigate,sponsor]);

  const approveUSDT = async () => {
    setLoading(true); 
    try {
      const accounts = await web3.eth.getAccounts();
      console.log('USDT_TOKEN_ADDRESS ' , USDT_TOKEN_ADDRESS)
      const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
      await usdtContract.methods.approve(MLM_CONTRACT_ADDRESS, web3.utils.toWei('50', 'ether')).send({ from: accounts[0] });
      // toast.success('USDT approved successfully!');
      await regsiterNewUser();
      //      navigate(`/welcomeuser?sponsorId=${sponsorId}`);
      

    } catch (error) {
      console.error('Error approving USDT:', error);
      toast.error('Failed to approve USDT');
      setLoading(false); // Hide loader if there's an error
    }
  };

  const regsiterNewUser = async () => {
    console.log('now we are inside register function')
    let sponserId = sponsorId;
    const accounts = await web3.eth.getAccounts();
    const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS);
    // await mlmContract.methods.registerUser(sponserId).send({from : accounts[0]});

    try{
      const gas = await mlmContract.methods.registerUser(sponserId).estimateGas({ from: accounts[0], value: 0 });
      console.log('Transaction gas: ', gas);
      setGasEstimate(gas);
      const tx = await mlmContract.methods.registerUser(sponserId).send({ from: accounts[0], gas });
      console.log('Transaction hash:', tx);
      toast.success('User Registered Successfully');
      navigate(`/welcomeuser?sponsorId=${sponserId}`);
    }catch(error){
      console.log('error ' , error)
      const errorMessage = error.message;
      toast.error(errorMessage);
    }
    setLoading(false); // Hide loader after approval
    

  }

  //Radio button functionality 
  const handleRadioChange = (event) => {
    if (event.target.value === 'no') {
      setSponsorId('0xFBc2DC59099Df95dc07353c602B70904c11d9707');
    } else {
      setSponsorId('');
    }
  };

  const sendDataBtn = async () => {
    if (!sponsorId) {
      console.log("sponsor id is required");
      alert("Sponsor ID is required");
      return; 
    }
    if (address) {
      setLoading(true); 
      await approveUSDT(sponsorId);
      // navigate('/welcomeuser');
    }
  };
  
  
  return (
    <div>
      <section className="sec01">
        <div className="contact_box container m-0">
          <div className="row">
            <div className="col-lg-6 p-0 text-center">
              <img src={about} className="about_img" alt="" />
            </div>
            <div className="col-lg-6 p-0 ">
              <div className="contact_form">
                <div className="text-center">
                  <img src={logo} alt="logo" className="logo logo-reg" />
                </div>
                <form className='f1'>
                  <div className='form-group'>
                    <label className='text-white mb-2'>Sponsor Id</label>
                    <input
                      type="text"
                      className="input_form"
                      name="sponsorId"
                      placeholder="Sponsor ID"
                      value={sponsorId}
                      onChange={(e) => setSponsorId(e.target.value)}
                      disabled={loading}
                    />
                  </div>
                  <div className='form-group'>
                    <label className='text-white mb-2'>User Id</label>
                    <input
                      type="text"
                      id="address_id"
                      className="input_form"
                      name="addr"
                      placeholder="Dapp Wallet Address"
                      value={address}
                      readOnly 
                    />
                  </div>
                  <label className="input_label1 w poppins">Do You Have Sponsor ID</label><br />
                  <input
                    type="radio"
                    name="sponsorRadio"
                    className="input_radio"
                    value="yes"
                    onChange={handleRadioChange}
                    disabled={loading}
                  /> <label className="input_label w mx-3">Yes</label>
                  <input
                    type="radio"
                    name="sponsorRadio"
                    className="input_radio"
                    value="no"
                    onChange={handleRadioChange}
                    disabled={loading}
                  /> <label className="input_label w ms-3">No</label><br />
                  <div className="margin_top text-center">
                    {loading ? ( 
                      <ColorRing
                        color="#ffffff"
                        height={80}
                        width={80}
                        wrapperClass="spinner1"
                      />
                    ) : (
                      <div className=" contact1 mukta-regular">
                        <input
                          type="button"
                          className="contact_bg1"
                          value="Connect Now"
                          disabled={!sponsorId }
                          onClick={sendDataBtn}
                        />
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Registration;


// RUNNING CODE 

// import React, { useState, useEffect } from 'react';
// import "./Registration.css"
// import { Web3 } from 'web3'
// import Swal from 'sweetalert2';
// import { TOKEN_ABI, USDT_TOKEN_ADDRESS , MLM_CONTRACT_ABI , MLM_CONTRACT_ADDRESS , DEFAULT_SPONSER_ID } from "../Imp"
// import { useAppContext } from '../AppContext';
// import logo from "../assets/img/logo.png"
// import about from "../assets/img/about_img01.png"
// import { useNavigate ,useParams,useLocation} from 'react-router';
// import toast from 'react-hot-toast'
// import { ColorRing } from 'react-loader-spinner'; 


// const Registration = () => {
//   // const {sponsor}=useParams()
//   const navigate=useNavigate();
//   const [web3, setWeb3] = useState(null);
//   const [balance, setBalance] = useState('');
//   const { setUsdtBalance, setAddress, address } = useAppContext();
//   const location=useLocation();
//   const sponsor=new URLSearchParams(location.search).get('sponserid')
//   const [sponsorId, setSponsorId] = useState(sponsor || '');
//   const [gasEstimate, setGasEstimate] = useState(0);
//   const [loading, setLoading] = useState(false); // Add loading state
//   const [radioButtonValue, setRadioButtonValue] = useState(sponsor ? 'yes' : 'no');


//   console.log("sponsor:",sponsor);

//   useEffect(() => {
//     const fetchData = async () => {

//       if(sponsor !== undefined){
//         setSponsorId(sponsor);
//     }
//        // Update radio button value based on sponsor presence
//   if (sponsor !== null) {
//     setRadioButtonValue('yes');
//   } else {
//     setRadioButtonValue('no');
//   }
//       if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         setWeb3(web3);

//         try {
//           await window.ethereum.request({ method: 'eth_requestAccounts' });

//           console.log("MetaMask connected successfully");

//           const accounts = await web3.eth.getAccounts();
//           const balanceInWei = await web3.eth.getBalance(accounts[0]);
//           const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

//           setAddress(accounts[0]);
//           setBalance(balanceInEther);
//           // Fetching USDT balance
//           const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
//           const usdtBalanceInWei = await usdtContract.methods.balanceOf(accounts[0]).call();
//           const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
//           setUsdtBalance(usdtBalanceInEther)


        

//         } catch (error) {
//           console.error('User denied account access', error);
//         }
//       } else {
//         console.log("MetaMask not found");
//         Swal.fire({
//           icon: "warning",
//           title: "Oops...",
//           text: "Please download MetaMask to use this feature!",
//           footer: '<a href="https://metamask.io/download/">Download MetaMask</a>',
//           customClass: {
//             popup: 'custom-swal-popup',
//             icon: 'custom-swal-icon',
//             title: 'custom-swal-title',
//             text: 'custom-swal-text',
//             confirmButton: 'custom-swal-confirm-button',
//             cancelButton: 'custom-swal-cancel-button',
//             footer: 'custom-swal-footer'
//           }
//         });
//       }
//     };

//     fetchData();
//   }, [navigate,sponsor]);

//   const approveUSDT = async () => {
//     setLoading(true); 
//     try {
//       const accounts = await web3.eth.getAccounts();

//       const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS)
//       const ifRegistered = await mlmContract.methods.users(accounts[0]).call()
//       console.log("ifRegistered", ifRegistered.isExist);
//       if (ifRegistered.isExist) {
//         navigate("/welcomeuser");
//       } else {
//       console.log('USDT_TOKEN_ADDRESS ' , USDT_TOKEN_ADDRESS)
//       const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
//       await usdtContract.methods.approve(MLM_CONTRACT_ADDRESS, web3.utils.toWei('50', 'ether')).send({ from: accounts[0] });
//       toast.success('USDT approved successfully!');
//       await regsiterNewUser();
//       //      navigate(`/welcomeuser?sponsorId=${sponsorId}`);
      
//       }
//     } catch (error) {
//       console.error('Error approving USDT:', error);
//       toast.error('Failed to approve USDT');
//       setLoading(false); // Hide loader if there's an error
//     }
//   };

//   const regsiterNewUser = async () => {
//     console.log('now we are inside register function')
//     let sponserId = sponsorId;
//     alert("sponser id  " + sponserId)
//     const accounts = await web3.eth.getAccounts();
//     const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS);
//     // await mlmContract.methods.registerUser(sponserId).send({from : accounts[0]});

//     try{
//       const gas = await mlmContract.methods.registerUser(sponserId).estimateGas({ from: accounts[0], value: 0 });
//       console.log('Transaction gas: ', gas);
//       setGasEstimate(gas);
//       const tx = await mlmContract.methods.registerUser(sponserId).send({ from: accounts[0], gas });
//       console.log('Transaction hash:', tx);
//       toast.success('User Registered Successfully');
//       navigate(`/welcomeuser?sponsorId=${sponserId}`);
//     }catch(error){
//       console.log('error ' , error)
//       const errorMessage = error.message;
//       toast.error(errorMessage);
//     }
//     setLoading(false); // Hide loader after approval
    

//   }

//   //Radio button functionality 
//   const handleRadioChange = (event) => {
//     if (event.target.value === 'no') {
//       setSponsorId('0xc1C0431D74CD86498040cca94a354dc9Fd3AC85e');
//     } else {
//       setSponsorId('');
//     }
//   };

//   const sendDataBtn = async () => {
//     if (!sponsorId) {
//       console.log("sponsor id is required");
//       alert("Sponsor ID is required");
//       return; 
//     }
//     if (address) {
//       setLoading(true); 
//       await approveUSDT(sponsorId);
//       // navigate('/welcomeuser');
//     }
//   };
  
  
//   return (
//     <div>
//       <section className="sec01">
//         <div className="contact_box container m-0">
//           <div className="row">
//             <div className="col-lg-6 p-0 text-center">
//               <img src={about} className="about_img" alt="" />
//             </div>
//             <div className="col-lg-6 p-0 ">
//               <div className="contact_form">
//                 <div className="text-center">
//                   <img src={logo} alt="logo" className="logo logo-reg" />
//                 </div>
//                 <form className='f1'>
//                   <div className='form-group'>
//                     <label className='text-white mb-2'>Sponsor Id</label>
//                     <input
//                       type="text"
//                       className="input_form"
//                       name="sponsorId"
//                       placeholder="Sponsor ID"
//                       value={sponsorId}
//                       onChange={(e) => setSponsorId(e.target.value)}
//                       disabled={loading}
//                     />
//                   </div>
//                   <div className='form-group'>
//                     <label className='text-white mb-2'>User Id</label>
//                     <input
//                       type="text"
//                       id="address_id"
//                       className="input_form"
//                       name="addr"
//                       placeholder="Dapp Wallet Address"
//                       value={address}
//                       readOnly 
//                     />
//                   </div>
//                   <label className="input_label1 w poppins">Do You Have Sponsor ID</label><br />
//                   <input
//                     type="radio"
//                     name="sponsorRadio"
//                     className="input_radio"
//                     value="yes"
//                     checked={radioButtonValue==='yes'}
//                     onChange={handleRadioChange}
//                     disabled={loading}
//                   /> <label className="input_label w mx-3">Yes</label>
//                   <input
//                     type="radio"
//                     name="sponsorRadio"
//                     className="input_radio"
//                     value="no"
//                     checked={radioButtonValue==='no'}
//                     onChange={handleRadioChange}
//                     disabled={loading}
//                   /> <label className="input_label w ms-3">No</label><br />
//                   <div className="margin_top text-center">
//                     {loading ? ( 
//                       <ColorRing
//                         color="#ffffff"
//                         height={80}
//                         width={80}
//                         wrapperClass="spinner1"
//                       />
//                     ) : (
//                       <div className=" contact1 mukta-regular">
//                         <input
//                           type="button"
//                           className="contact_bg1"
//                           value="Connect Now"
//                           disabled={!sponsorId }
//                           onClick={sendDataBtn}
//                         />
//                       </div>
//                     )}
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Registration;