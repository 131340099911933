import React,{useEffect,useState} from 'react'
import Header from '../Header-Footer/Header'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../AppContext';
import axios from 'axios';
import qs from 'qs';

const ViewTable = () => {
    const {  address } = useAppContext();
    const [data, setData] = useState('')
    const location=useLocation();
    const [Level, setLevel] = useState(1)
    const level=new URLSearchParams(location.search).get('level')




console.log(address);
    useEffect(() => {
        callData(address,level)
        setLevel(level)
    }, [])

    const callData = (address,level) => {

        let data = qs.stringify({
        'address': address ,
        'level':level
        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://roksa.io/Api/partners_level.php',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        (JSON.stringify(response.data));
        console.log(response.data);
        setData(response.data);
        })
        .catch((error) => {
        console.log(error);
        });

}
    return (
        <>
            <div class='dashboard-app'>
                <Header />
                <div class='dashboard-content'>
                    <div class='container container-hidden'>
                    <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button partners-title1" type="button" data-bs-toggle="collapse" data-bs-target="" aria-expanded="true" aria-controls="collapseOne">
                                        Level {level}  
                                    </button>
                                </h2>
                                {/* <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Program</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">x3</option>
                                                        <option class="b" value="2">x4</option>
                                                        <option class="b" value="3">xXx</option>
                                                        <option class="b" value="4">xGold</option>
                                                        <option class="b" value="5">xQore</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Level</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">1</option>
                                                        <option class="b" value="2">2</option>
                                                        <option class="b" value="3">3</option>
                                                        <option class="b" value="4">4</option>
                                                        <option class="b" value="5">5</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="form-lable w">Search ID / address</label>
                                                    <input type="text" class="input-form" />
                                                </div>
                                                <div class="d-flex">
                                                    <input type="submit" value="Apply filters" class="sub-btn" />
                                                    <input type="reset" value="Reset filters" class="rest-btn " />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="partners-table-box">
                            <div class="partners-table">
                                <table class="table-partners02 table-responsive">
                                    <tr class="table-row">
                                        <th class="table-heading w">Date</th>
                                        <th class="table-heading w">Address</th>

                                        <th class="table-heading w">Profit USDT</th>
                                        {/* <th class="table-heading w">Profit BNB</th> */}

                                    </tr>
                                    {
                                                data && data.members.map((item, index) => {

                                                    return (
                                    <tr class="table-row">
                                        <td class="table-data">{item.created_at}</td>

                                        <td class="table-data">{item.downline_id}</td>
                                        <td class="table-data">{item.income/1000000000000000000}</td>
                                        {/* <td class="table-data">0.0018</td> */}
                                    </tr>
                 )
                })
                }                  
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTable