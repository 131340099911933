import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { useAppContext } from '../AppContext';
import UnknownUser from '../assets/img/UnknownUser.png';
import Partners from '../assets/img/Partners.png';
import Ratio from '../assets/img/Ratio.png';
import BnbBusd from '../assets/img/bnbBusd.png';
import Header from '../Header-Footer/Header';
import x1 from '../assets/img/35.png';
import x2 from '../assets/img/36.png';
import x3 from '../assets/img/37.png';
import x4 from '../assets/img/38.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { TOKEN_ABI, USDT_TOKEN_ADDRESS, MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS, } from "../Imp"
import { Web3 } from 'web3';
import moment from 'moment';
import toast from 'react-hot-toast';
import { GrPrevious, GrNext } from "react-icons/gr";
import axios from 'axios';
import qs from 'qs';
const FormData = require('form-data');

// import React, { useEffect, useState } from 'react';




// const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const Main = () => {
    const { usdtBalance, address } = useAppContext();
    const [bnbPrice, setBnbPrice] = useState(0)
    const [web3, setWeb3] = useState(null);
    const { setUsdtBalance, setAddress } = useAppContext();
    const [data, setData] = useState('')
    const [balance, setBalance] = useState('');
    const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [userData, setUserData] = useState('')

    useEffect(() => {
        if (address) {
            localStorage.setItem('userAddress', address);
        }
    }, [address]);

    useEffect(() => {
        setAddress(localStorage.getItem("userAddress"))
        fetchBnbPrice()
        getData();
        callData(localStorage.getItem("userAddress"))
    }, [currentPage, perPage])


    const callData = (address) => {

        let data = qs.stringify({
            'address': address
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://roksa.io/Api/address_data.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                (JSON.stringify(response.data));
                console.log(response.data);
                setUserData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }




    const getData = async () => {
        try {
            const response = await fetch(`https://roksa.io/Api/registerUsers.php?page=${currentPage}&limit=${perPage}`);
            const result = await response.json();
            setData(result.users);
            setTotalPages(result.total_pages);
            console.log(result.users);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const navigatePage = (page) => {
        setCurrentPage(page);
    };

    const fetchBnbPrice = async () => {
        try {
            const response = await fetch("https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT");
            const data = await response.json()
            setBnbPrice(data.price)
        } catch (error) {
            console.error("Error Fetching BNB price", error);
        }
    }

    const blockchainExp = (hash) => {
        const exploreURL = `https://bscscan.com/tx/${hash}`
        window.open(exploreURL, '_blank')
    }



    useEffect(() => {
        if (window.ethereum) {
            const web3Instance = new Web3(window.ethereum)
            setWeb3(web3Instance)
        }
    }, []) // Empty dependency array ensures this runs only once on initial render

    function convertToRelativeTime(timestampInSeconds) {
        const timestampInMilliseconds = timestampInSeconds * 1000;
        const relativeTime = moment(timestampInMilliseconds).fromNow();
        return relativeTime;
    }

    // const  AccountDetails=async(address)=>{

    //         if (window.ethereum) {
    //             const web3Instance = new Web3(window.ethereum)
    //             setWeb3(web3Instance)
    //         }
    //         try {
    //             const account = address;
    //             console.log("account:",account);
    //             const balanceInWei = await web3.eth.getBalance(account);
    //             const balanceInEther = web3.utils.fromWei(balanceInWei, 'ether');

    //             setAddress(account)
    //             console.log(account);
    //             localStorage.setItem("userAddress",account)
    //             setBalance(balanceInEther)

    //             // Fetching USDT balance
    //             const usdtContract = new web3.eth.Contract(TOKEN_ABI, USDT_TOKEN_ADDRESS);
    //             const usdtBalanceInWei = await usdtContract.methods.balanceOf(account).call();
    //             const usdtBalanceInEther = web3.utils.fromWei(usdtBalanceInWei, 'ether');
    //             setUsdtBalance(usdtBalanceInEther)

    //             const mlmContract = new web3.eth.Contract(MLM_CONTRACT_ABI, MLM_CONTRACT_ADDRESS)
    //             const ifRegistered = await mlmContract.methods.users(account).call()
    //             console.log(ifRegistered.isExist);
    //             if (ifRegistered.isExist) {
    //                 navigate("/welcomeuser");
    //             }
    //             // }else{
    //             //     toast.error("Invalid Wallet Address")
    //             // }
    //         } catch (error) {
    //             toast.error("Invalid Wallet Address")

    //             console.error("error", error);
    //         }
    //     }


    const copyText = (text) => {
        const fullText = `Roksa.io/register?sponserid=${text}`;
        const textarea = document.createElement('textarea');
        textarea.value = fullText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success("copy to clipboard")
    };

    const copyContractAdd = (text) => {
        const fullText = text;
        const textarea = document.createElement('textarea');
        textarea.value = fullText;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success("copy to clipboard")

    }
    return (
        <>
            <div class='dashboard-app'>
                <Header />
                <div class='dashboard-content'>
                    <div class='container'>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="d-flex icon-box">
                                    <div class="icon-box-img">
                                        <div class="box"><img class="user-img" src={UnknownUser} alt="" /> </div>
                                    </div>
                                    <div class="icon-box-text">
                                        {/* <h4 class="icon-box-title w">id 1</h4> */}
                                        {/* <p class="icon-text w">{address.slice(0,26)}</p> */}
                                        <p class="icon-text w">{`${address.slice(0, 8)}......${address.slice(-8)}`}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 align-self">
                                <div class="ani-1">
                                    <div class="context">
                                        <div class="personal_link_box d-flex">
                                            <div class="moving-shape">
                                                <p class="p_title w">Personal link</p>
                                                <h2 class="p_text w">Roksa.io/{address.slice(-8)}</h2>
                                            </div>
                                            <div class="copy_btn_box">
                                                <a class="copy_btn" onClick={() => copyText(address)}><i class="fa-regular fa-copy"></i> Copy</a>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="area-1" >
                                        <ul class="circles">
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div class="margin-top">
                            <div class="row">
                                <div class="col-lg-3 col-md-6 col-sm-6 col_2">
                                    <div class="partners">
                                        <h5 class="partners-title">Today Income</h5>
                                        <h4 class="partners-text w">{userData.today_income ? userData.today_income : '0'}</h4>
                                        <img class="partners1" src={Ratio} alt="" />

                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-6  col_2">
                                    <div class="partners">
                                        <h5 class="partners-title">Partners</h5>
                                        {/* <NavLink to="/tokentransfer"><button className='btn'>Transfar</button></NavLink> */}
                                        <h4 class="partners-text w">{userData.refferal_counts ? userData.refferal_counts : '0'}</h4>


                                        <img class="partners2" src={Partners} alt="" />

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6  col_2">
                                    <div class="partners">
                                        <h5 class="partners-title">Profits</h5>
                                        <img class="partners4" src={BnbBusd} alt="" />
                                        <div class="d-flex part20">
                                            <h4 class="partners-text w">{userData.total_income} USDT</h4>
                                            <span class="g green-text"><i class="fa-solid fa-arrow-up me-2"></i> 2</span>
                                        </div>
                                        <div class="d-flex part20 mt-3">
                                            {/* <h4 class="partners-text w">{(usdtBalance / bnbPrice).toFixed(4)} BNB</h4> */}
                                            <h4 class="partners-text w">{(userData.total_income / bnbPrice).toFixed(4)} BNB</h4>
                                            <span class="w green-text"><i class="fa-solid fa-arrow-up me-2"></i> 252</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section class="sec_2 ">
                        <h2 class="sec_2_title w">Roksa Programs </h2>
                        <div class="margin-top">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-3">
                                    <div class="x3">
                                        <div class="d-flex part20 mb-2">
                                            <h3 class="x3_title w">X1</h3>
                                            {/* <h3 class="x3_title w">369 245 USDT</h3> */}
                                        </div>
                                        <div class="d-flex part20 part20-1">

                                            <div className='x-image '>
                                                <img src={x1} alt="x-image" />
                                            </div>
                                            <div class="align-self_end">
                                                <NavLink to="/x-one?pool1=1" className="preview preview1">Preview <span class="arrow"><i class="fa-solid fa-arrow-right arrow1"></i></span></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 mt-3">
                                    <div class="x4">
                                        <div class="d-flex part20 mb-2">
                                            <h3 class="x3_title w">X2</h3>
                                            {/* <h3 class="x3_title w">369 245 USDT</h3> */}
                                        </div>
                                        <div class="d-flex part20 part20-1">

                                            <div className='x-image'>
                                                <img src={x2} alt="x-image" />
                                            </div>
                                            <div class="align-self_end">
                                                <NavLink to="/x-two" className="preview preview2">Preview <span class="arrow"><i class="fa-solid fa-arrow-right arrow2"></i></span></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="sec_2 ">
                        <h2 class="sec_2_title w">Recent Transactions</h2>
                        <div class="margin-top">
                            <div class="row">
                                <div class="col-lg-8 col-md-8">
                                    <div className="cont-overflow-scroll">
                                        <div class="table-recent">
                                            {
                                                data && data.map((item, index) => {
                                                    // const sliceAddress = item.address.slice(0, 16)
                                                    return (
                                                        <div class="d-flex part20 table-row">
                                                            <div class="icon-box1">
                                                                <div>
                                                                    {/* <i class="fa-solid fa-user user"></i> */}
                                                                    <span class="joined">{(item.hash).slice(0, 42)}</span>
                                                                    {/* <span class="user-id">{item.id} </span> */}
                                                                </div>
                                                            </div>
                                                            <div class="align-self">
                                                                <h3 class="minutes"><i class="fa-solid fa-arrow-up-right-from-square w arrow-up-right" onClick={() => blockchainExp(item.hash)}></i> {convertToRelativeTime(item.time)}</h3>
                                                            </div>
                                                        </div>)
                                                })
                                            }


                                            <div className='pagenation-1'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">

                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link" onClick={() => navigatePage(currentPage - 1)}>
                                                                <GrPrevious />
                                                            </a>
                                                        </li>



                                                        {Array.from({ length: Math.min(totalPages, 3) }, (_, index) => {
                                                            let page = 1;
                                                            if (currentPage === totalPages) {
                                                                page = totalPages - 2 + index;
                                                            } else {
                                                                page = Math.min(Math.max(currentPage - 1 + index, index + 1), totalPages);
                                                            }
                                                            return (
                                                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                                                    <a className="page-link" onClick={() => navigatePage(page)}>{page}</a>
                                                                </li>
                                                            );
                                                        })}

                                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                            <a className="page-link" onClick={() => navigatePage(currentPage + 1)}>
                                                                <GrNext />
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </nav>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4">
                                    <div class="table-recent members_received">
                                        <h4 class="members-title">Members received                                         </h4>
                                        <h3 class="x3_title w c-green">{userData.members_received}                                    </h3>
                                        {/* <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                                    </div>
                                    <div class="table-recent mt-3">
                                        <h4 class="members-title">Members Total</h4>
                                        <h3 class="x3_title w c-green">{userData.members_total}</h3>
                                        {/* <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                                        {/* <div class="border-w"></div> */}
                                        {/* <h3 class="x3_title w">22 502.3186 BNB                                    </h3> */}
                                        {/* <span class="g green-text_m"><i class="fa-solid fa-arrow-up me-2"></i> 2985585 USDT</span> */}
                                    </div>
                                    <div class="table-recent mt-3">
                                        <h4 class="members-title">Roksa Contracts Address</h4>
                                        <div class="border-w"></div>
                                        <div class="d-flex part20">

                                            <div class="fors2">
                                                <h4 class="x3-text w mb-0 c-green">0xFC745FA5....5eeeD86ef6615 <i class="fa-regular fa-copy mx-2" onClick={() => copyContractAdd(MLM_CONTRACT_ADDRESS)}></i> <i class="fa-solid fa-link" onClick={() => blockchainExp(MLM_CONTRACT_ADDRESS)}></i></h4>
                                            </div>
                                        </div>
                                        <div class="border-w"></div>
                                        <div class="border-w"></div>
                                        <h4 class="members-title"> Turnover, USDT </h4>
                                        <h3 class="x3_title w c-green">{userData.turnover}</h3>
                                        {/* <span class="g green-text_m">+ 22 568.2</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Main