import './assets/css/style.css';
import './assets/css/homepage.css';
import { BrowserRouter as Router, Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";
import Header from "./Header-Footer/Header";
import Sidebar from "./Component/Sidebar";
import Footer from "./Header-Footer/Footer";
import Main from "./Pages/Main";
import Income from './Pages/Income';
import Links from './Pages/Links';
import Partners from './Pages/Partners';
import Homepage from './HomePage/Homepage';
import MainHeader from './Header-Footer/MainHeader';
import Connectwallet from './connect wallet/Connectwallwt';
import Registration from './Registration/Registration';
import Welcomeuser from './connect wallet/Welcomeuser';
import toast, { Toaster } from 'react-hot-toast';
import TokenTransfer from './connect wallet/TokenTransfer';
import LavelOne from './Pages/LavelOne';
import LavelTwo from './Pages/LavelTwo';
import LavelThree from './Pages/LavelThree';
import LavelFour from './Pages/LavelFour';
import ViewTable from './Pages/ViewTable';
import Document from './HomePage/Document';
import { useAppContext } from './AppContext';




function App() {
  // const { address } = useAppContext();
  let address=localStorage.getItem('userAddress')
  const HomePage = () => {
    return (
      <>
         <Outlet />
      </>
    )
  }


  const Dashboard = () => {
    return (
      <>
       {/* {address ? */}
          {/* ( */}
            <div class="container">
            <div class='dashboard'>
              <Sidebar />
              <Outlet />
            </div>
          </div>
          {/* ) :
          (
            <Navigate to="/" />
          )
        } */}


      </>
    )

  }


  return (
    <>
      <Router>
        <Routes>

          <Route element={<Dashboard />}>
            <Route path="/dashboard" element={<Main />} />
            <Route path="/income" element={<Income />} />
            <Route path="/link" element={<Links />} />
            <Route path="/partners" element={<Partners/>} />
            <Route path="/x-one" element={<LavelOne/>} />
            <Route path="/x-two" element={<LavelTwo/>} />
            <Route path="/x-three" element={<LavelThree/>} />
            <Route path="/x-four" element={<LavelFour/>} />
            <Route path="/view-table" element={<ViewTable/>} />
            
          </Route>

          <Route element={<HomePage />}>
            <Route path="*" element={<HomePage />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/Connectwallet" element={<Connectwallet/>}/>
            <Route path="/register" element={<Registration />} />
            <Route path="/welcomeuser" element={<Welcomeuser />} />
            <Route path="/tokentransfer" element={<TokenTransfer />} />
            <Route path="/document" element={<Document />} />
           
            </Route>

           
        </Routes>
      </Router>
      <Toaster/>
    </>
  );
}

export default App;
