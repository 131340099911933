import React ,{useState,useEffect}from 'react';
// import "./Registration.css"
import {Web3} from 'web3' 
import Swal from 'sweetalert2';
import { TOKEN_ABI, USDT_TOKEN_ADDRESS } from "../Imp"
import { useAppContext } from '../AppContext';
import logo from "../assets/img/logo.png"
import about from "../assets/img/about_img01.png"

const TokenTransfer = () => {

    
        const [web3, setWeb3] = useState(null);
        const [contract, setContract] = useState(null);
        const [account, setAccount] = useState(null);
        const [recipient, setRecipient] = useState('');
        const [amount, setAmount] = useState(0);
        useEffect(() => {
            const initWeb3 = async () => {
                const web3Instance = new Web3(window.ethereum);
                setWeb3(web3Instance);
                try {
                    const accounts = await web3Instance.eth.getAccounts();
                    console.log('account fetched' ,accounts)
                    setAccount(accounts[0]);
                } catch (error) {
                    console.error('Error fetching accounts', error);
                }
            };
    
            initWeb3();
        }, []);
    
        useEffect(() => {
            if (web3) {
                const contractInstance = new web3.eth.Contract(
                  TOKEN_ABI,
                    USDT_TOKEN_ADDRESS
                );
                setContract(contractInstance);
            }
        }, [web3]);
    
        const handleTransfer = async () => {
            if (contract && recipient && amount > 0) {
                try {
                    const amountInWei = web3.utils.toWei(amount.toString(), 'ether');
                    await contract.methods.transfer(recipient, amountInWei ).send({ from: account });
                    console.log('Transfer successful');
                    // Add any additional logic here upon successful transfer
                } catch (error) {
                    console.error('Transfer failed', error);
                }
            }
        };



  return (
    < >
    <section className="sec01">
          <div className="contact_box container m-0">
            <div className="row token-">
              <div className="col-lg-6 p-0 text-center">
                <img src={about} className="about_img" alt="" />
              </div>
              <div className="col-lg-6 p-0 text-center">
                <div className="contact_form token-transfar-content">
                  <div className="text-center mb-3">
                    <img src={logo} alt="logo" className="logo" />
                  </div>
                  <h2>Transfer Tokens</h2>
                    <p>Your account: {account}</p>
                    <input className='form-control' type="text" placeholder="Recipient address" value={recipient} onChange={(e) => setRecipient(e.target.value)} />
                    <input className='form-control mt-3 mb-3' type="number" placeholder="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                   
                    <div className="margin_top text-center">
                    <div className=" contact1 mukta-regular ">
                      <input type="button" className="contact_bg1" value="Transfer" onClick={handleTransfer} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default TokenTransfer