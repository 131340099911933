import React,{useState,useEffect} from 'react'
import Header from '../Header-Footer/Header'
import { NavLink } from 'react-router-dom'
import { useAppContext } from '../AppContext';
import axios from 'axios';
import qs from 'qs';

const Partners = () => {
    const {  address } = useAppContext();
    const [data, setData] = useState('')


console.log(address);
    useEffect(() => {
        callData(address)
    }, [])

    const callData = (address) => {

        let data = qs.stringify({
        'address': address 
        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://roksa.io/Api/partners_count.php',
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        (JSON.stringify(response.data));
        console.log(response.data);
        setData(response.data);
        })
        .catch((error) => {
        console.log(error);
        });

}
    return (
        <>
            <div class='dashboard-app'>
                <Header />
                <div class='dashboard-content'>
                    <div class='container container-hidden'>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button partners-title1" type="button" data-bs-toggle="" data-bs-target="" aria-expanded="false" aria-controls="collapseOne">
                                        Partners 
                                    </button>
                                </h2>
                                {/* <div id="collapseOne" class="accordion-collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Program</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">x3</option>
                                                        <option class="b" value="2">x4</option>
                                                        <option class="b" value="3">xXx</option>
                                                        <option class="b" value="4">xGold</option>
                                                        <option class="b" value="5">xQore</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Level</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">1</option>
                                                        <option class="b" value="2">2</option>
                                                        <option class="b" value="3">3</option>
                                                        <option class="b" value="4">4</option>
                                                        <option class="b" value="5">5</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="form-lable w">Search ID / address</label>
                                                    <input type="text" class="input-form" />
                                                </div>
                                                <div class="d-flex">
                                                    <input type="submit" value="Apply filters" class="sub-btn" />
                                                    <input type="reset" value="Reset filters" class="rest-btn " />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="partners-table-box">
                            <div class="partners-table">
                                <table class="table-partners02 table-responsive">
                                    <tr class="table-row">
                                        <th class="table-heading w">Level</th>
                                        <th class="table-heading w">Total Team</th>

                                        <th class="table-heading w">Action</th>

                                    </tr>
                                    {
                                                data && data.members.map((item, index) => {

                                                    return (
                                    <tr class="table-row">
                                        <td class="table-data">{item.level}</td>
                                      
                                        <td class="table-data">{item.team_count}</td>
                                        <td class="table-data"><NavLink to={`/view-table?level=${item.level}`} className='btn btn-primary'>View</NavLink></td>
                                    </tr>
)
})
}

                                    {/* <tr class="table-row">
                                     
                                        <td class="table-data">2</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                       
                                        <td class="table-data">3	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                       
                                        <td class="table-data">4	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        
                                        <td class="table-data">5	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        
                                        <td class="table-data">6	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        
                                        
                                        <td class="table-data">7	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                      
                                        <td class="table-data">8	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-data">9	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-data">10	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr>
                                    <tr class="table-row">
                                        <td class="table-data">11	</td>
                                        <td class="table-data">0</td>
                                        <td class="table-data"><NavLink to='/view-table' className='btn btn-primary'>View</NavLink></td>
                                    </tr> */}
                                </table>

                            </div>
                            <button class="see-more" href="#">See More</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partners