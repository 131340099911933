import React, { useEffect } from 'react';
import { Chart } from 'react-google-charts';
import Activity_green from '../assets/img/activity_green.webp';
import Partners from '../assets/img/Partners.png';
import Ratio from '../assets/img/Ratio.png';
import Team from '../assets/img/Team.png';
import BnbBusd from '../assets/img/bnbBusd.png';
import Header from '../Header-Footer/Header';

const Links = () => {

    useEffect(() => {
        // Your chart drawing logic
        drawCharts();
      }, []);
    const drawCharts = () => {
        const barData = [
          ['Day', 'Page Views', 'Unique Views'],
          ['Sun', 1050, 600],
          ['Mon', 1370, 910],
          ['Tue', 660, 400],
          ['Wed', 1030, 540],
          ['Thu', 1000, 480],
          ['Fri', 1170, 960],
          ['Sat', 660, 320],
        ];
    
        const barOptions = {
          focusTarget: 'category',
          backgroundColor: 'transparent',
          colors: ['cornflowerblue', 'tomato'],
          fontName: 'Open Sans',
          chartArea: {
            left: 50,
            top: 10,
            width: '100%',
            height: '70%',
          },
          bar: {
            groupWidth: '80%',
          },
          hAxis: {
            textStyle: {
              fontSize: 11,
            },
          },
          vAxis: {
            minValue: 0,
            maxValue: 1500,
            baselineColor: '#DDD',
            gridlines: {
              color: '#DDD',
              count: 4,
            },
            textStyle: {
              fontSize: 11,
            },
          },
          legend: {
            position: 'bottom',
            textStyle: {
              fontSize: 12,
            },
          },
          animation: {
            duration: 1200,
            easing: 'out',
            startup: true,
          },
        };
    
        return (
          <Chart
            width={'100%'}
            height={'240px'}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={barData}
            options={barOptions}
            legendToggle
          />
        );
      };
  return (
    <>
      <div class='dashboard-app'>
           <Header/>
            <div class='dashboard-content'>
                <div class='container container-hidden'>
                      <div class="link-title-box">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <h4 class="link-title">Links </h4>
                            </div>
                            <div class="col-lg-6 col-sm-12 align-self right">
                                <span class="info mx-2 w"> ID 468</span>
                            </div>
                        </div>    
                      </div> 
                      <div class="link_sen02">
                        <div class="row">
                            <div class="col-lg-6 col_2">
                                <div class="chart_01">
                                    <main>
                                    {drawCharts()} 
                                    </main>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col_2">
                                        <div class="partners">
                                            <h5 class="partners-title">Link clicks                                            </h5>
                                            <h4 class="partners-text w">878</h4>
                                            <img class="partners1" src={Partners} alt="" />
                                            <div class="d-flex green-box">
                                                <span class="g green-text"><i class="fa-solid fa-arrow-up me-2"></i> 2</span>
                                                <img class="green-img" src={Activity_green} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col_2">
                                        <div class="partners">
                                            <h5 class="partners-title">Partners                                            </h5>
                                            <h4 class="partners-text w">27</h4>
                                            <img class="partners2" src={Ratio} alt="" />
                                            <div class="d-flex green-box">
                                                <span class="g green-text"><i class="fa-solid fa-arrow-up me-2"></i> 2</span>
                                                <img class="green-img" src={Activity_green} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6 col_2">
                                        <div class="partners">
                                            <h5 class="partners-title">Team                                            </h5>
                                            <h4 class="partners-text w">31</h4>
                                            <img class="partners3" src={Team} alt="" />
                                            <div class="d-flex green-box">
                                                <span class="w green-text"><i class="fa-solid fa-arrow-up me-2"></i> 0 %</span>
                                                <img class="green-img" src={Activity_green} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-sm-6 col_2">
                                        <div class="partners">
                                            <h5 class="partners-title">Profits</h5>
                                            <img class="partners4" src={BnbBusd} alt="" />
                                            <div class="d-flex part20">
                                                    <h4 class="partners-text w">115 BUSD                                                    </h4>
                                                    <span class="g green-text"><i class="fa-solid fa-arrow-up me-2"></i> 2</span>
                                            </div>
                                            <div class="d-flex green-box">
                                                <span class="w green-text"><i class="fa-solid fa-arrow-up me-2"></i> 0 %</span>
                                                <img class="green-img" src={Activity_green} alt="" />
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="">
                        <div class="row ">
                                <div class="col-lg-6 col-sm-6 mt-3 col_2">
                                    <div class="x3">
                                        <div class="d-flex part20">
                                            <h3 class="x3_title w">Personal link                                            </h3>
                                            <h3 class="x3_title w">orsage.io/b/5lvio6</h3>
                                        </div> 
                                        <div class="d-flex part20">
                                            <div class="blue-box">
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                    <div class="blue-box-item blue-box-item1"></div>
                                                </div>
                                            </div>
                                            <div class="align-self_end">
                                                <a href="#" class="preview preview1">Copy <i class="fa-regular fa-copy ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 mt-3 col_2">
                                    <div class="x4">
                                        <div class="d-flex part20">
                                            <h3 class="x3_title w">Personal link MaxQore                                            </h3>
                                            <h3 class="x3_title w">rsage.io/mq/5lvio6
                                            </h3>
                                        </div> 
                                        <div class="d-flex part20">
                                            <div class="blue-box">
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                    <div class="blue-box-item blue-box-item2"></div>
                                                </div>
                                            </div>
                                            <div class="align-self_end">
                                                <a href="#" class="preview preview2">Copy <i class="fa-regular fa-copy ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 mt-3 col_2">
                                    <div class="xq">
                                        <div class="d-flex part20">
                                            <h3 class="x3_title w">Personal link xQore   </h3>
                                            <h3 class="x3_title w">ge.io/q/5lvio6
                                            </h3>
                                        </div> 
                                        <div class="d-flex part20">
                                            <div class="blue-box">
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                    <div class="blue-box-item blue-box-item3"></div>
                                                </div>
                                            </div>
                                            <div class="align-self_end">
                                                <a href="#" class="preview preview3">Copy <i class="fa-regular fa-copy ms-2"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6 mt-3 col_2">
                                    <div class="xm">
                                        <div class="d-flex part20">
                                            <h3 class="x3_title w">Create custom invite PDF                                            </h3>
                                            <h3 class="x3_title w"></h3>
                                        </div> 
                                        <div class="d-flex part20">
                                            <div class="blue-box">
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                </div>
                                                <div class="blue-box-grid d-flex">
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                    <div class="blue-box-item blue-box-item4"></div>
                                                </div>
                                            </div>
                                            <div class="align-self_end">
                                                <a href="#" class="preview preview4">Create PDF <span class="arrow"><i class="fa-solid fa-arrow-right arrow4"></i></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Links