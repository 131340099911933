//     import React, { useState, useEffect } from 'react';
//     import logo from '../assets/img/logo.png';
//     // import Swal from 'sweetalert2';
//     import { Web3 } from 'web3';
//     import { NavLink } from 'react-router-dom';
//     import {abi,contractAddress} from "../Imp"
    
//     import { useAppContext } from '../AppContext';


//     const Sidebar = () => {
//         const [open, setOpen] = useState(false);
//         const [web3, setWeb3] = useState(null);

//         const { address} = useAppContext();


//         const toggleDropdown = () => {
//             setOpen(!open);
//         };

//         const [open1, setOpen1] = useState(false);

//         const toggleDropdown1 = () => {
//             setOpen1(!open1);
//         };


//         useEffect(() => {
//             if (window.ethereum) {
//                 const web3Instance = new Web3(window.ethereum);
//                 setWeb3(web3Instance);
//             }
//         }, []);



// const contract= new web3.eth.Contract(abi,contractAddress)
// const sendTransaction = async (toAddress, amount) => {
//   try {
//     const from = address;
//     const value=await web3.utils.toWei(amount.toString(),"ether")
//     const tx=await contract.methods.transfer(toAddress,value).send({from})
//     tx.wait()
//     console.log("Transaction sent :",tx.transactionHash);
//   } catch (error) {
//     console.error('Error sending transaction:', error);
//   }
// };

//         return (
//             <>
                
//                 <div className="dashboard-nav">
//                     <header className="nav_header1">
//                         <NavLink to="/" className="brand-logo">
//                             <img src={logo} className="logo dashboard-logo phone" alt="" />
//                         </NavLink>
//                         <a href="#!" className="menu-toggle"><i className="fas fa-bars"></i></a>
//                     </header>

//                     <div className='navbar-menu'>
//                         <ul>
//                             <li className='list list-active'>
//                                 <NavLink to="/Dashboard" className="list-link">Dashboard </NavLink>
//                             </li>
//                             <li className='list mt-2'>
//                                 <a className="list-link  list-link-flex " onClick={toggleDropdown}>
//                                     <div className=''><span><i className="fas fa-photo-video"></i></span>Team</div>
//                                     <div className=''><span><i className="fa-solid fa-caret-down"></i></span></div>
//                                 </a>
//                             </li>
//                             {open &&
//                                 <div className='list-dropdownmenu'>
//                                     <li><NavLink to="/partners" className="">Partners</NavLink></li>
//                                     <li><NavLink to="/link" className="">Links</NavLink></li>
//                                     <li><NavLink to="/income" className="">Income</NavLink></li>
//                                 </div>
//                             }
//                             <li className='list mt-2'>
//                                 <NavLink to="/dashboard" className="list-link"><span><i className="fas fa-tachometer-alt"></i></span> Marathon</NavLink>
//                             </li>
//                             <li className='list mt-2'>
//                                 <NavLink to="/dashboard" className="list-link"><span><i className="fas fa-file-upload"></i></span> Social</NavLink>
//                             </li>
//                             <li className='list mt-2'>
//                                 <NavLink to="/dashboard" className="list-link"><span><i className="fas fa-file-upload"></i></span>NFTs</NavLink>
//                             </li>
//                             <li className='list mt-2'>
//                                 <a className="list-link  list-link-flex " onClick={toggleDropdown1}>
//                                     <div className=''><span><i className="fas fa-users"></i></span>Information</div>
//                                     <div className=''><span><i className="fa-solid fa-caret-down"></i></span></div>
//                                 </a>
//                             </li>
//                             {open1 &&
//                                 <div className='list-dropdownmenu'>
//                                     <li><NavLink to="/partners" className="">Partners</NavLink></li>
//                                     <li><NavLink to="/link" className="">Links</NavLink></li>
//                                     <li><NavLink to="/income" className="">Income</NavLink></li>
//                                 </div>
//                             }
//                             <li className='list mt-2'>
//                                 <NavLink to="/" className="list-link"><span><i className="fas fa-file-upload"></i></span>Promo & PD</NavLink>
//                             </li>
//                             <div className='list-btn-div'>
//                                 <button className=' btn matamask-btn' onClick={()=>sendTransaction("0x2da98170995BBBd2AfAB15ba76241cf801394290",0.1)}>Transfer</button>
//                             </div>
//                         </ul>
//                     </div>
//                 </div>
//             </>
//         );
//     };

//     export default Sidebar;









import React, { useState, useEffect } from 'react';
import logo from '../assets/img/logo.png';
// import Swal from 'sweetalert2';
import { Web3 } from 'web3';
import { NavLink } from 'react-router-dom';
import { abi, contractAddress } from "../Imp"
import { useAppContext } from '../AppContext';

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const [web3, setWeb3] = useState(null);

  const { address } = useAppContext();

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const [open1, setOpen1] = useState(false);

  const toggleDropdown1 = () => {
    setOpen1(!open1);
  };
  // useEffect(() => {
  //   if (window.ethereum) {
  //     const web3Instance = new Web3(window.ethereum);
  //     setWeb3(web3Instance);
  //   }
  //   // //set chain id 

  //          window.ethereum.request({
  //         method:"wallet_addEthereumChain",
  //         params:[
  //           {
  //             chainId:"0x38",
  //             chainName:"BNB Chain",
  //             nativeCurrency:{
  //               name:"BNB",
  //               symbol:'BNB',
  //               decimals:18
  //             },
  //             rpcUrls: ['https://bsc-dataseed.binance.org/'],
             
  //             blockExplorerUrls: ['https://bscscan.com/'],
  //           }
  //         ]
  //       })
  // }, []);

  // const sendTransaction = async (toAddress, amount) => {
  //   try {


  //     const from = address;
  //     const value = await web3.utils.toWei(amount.toString(), 'ether');
  //     const tx = await new web3.eth.Contract(abi, contractAddress).methods.transfer(toAddress, value).send({ from});
  //     await tx.wait();
  //     console.log("Transaction sent:", tx.transactionHash);
  //   } catch (error) {
  //     console.error('Error sending transaction:', error);
  //   }
  // };

          return (
            <>
                
                <div className="dashboard-nav">
                    <header className="nav_header1">
                        <NavLink to="/" className="brand-logo">
                            <img src={logo} className="logo dashboard-logo phone" alt="" />
                        </NavLink>
                        <a href="#!" className="menu-toggle"><i className="fas fa-bars"></i></a>
                    </header>

                    <div className='navbar-menu'>
                        <ul>
                            <li className='list list-active'>
                                <NavLink to="/Dashboard" className="list-link">Dashboard </NavLink>
                            </li>
    
                           <li className='list mt-2'>
                                <NavLink to="/partners" className="list-link"><span><i className="fas fa-file-upload"></i></span>Partners</NavLink>
                            </li>
                            <li className='list mt-2'>
                                <NavLink to="/income" className="list-link"><span><i className="fas fa-file-upload"></i></span>Income</NavLink>
                            </li>
                            <li className='list mt-2'>
                                <NavLink to="/document" className="list-link"><span><i className="fas fa-file-upload"></i></span>Documents</NavLink>
                            </li>
                            {/* <li className='list mt-3 logout-btn'>
                                <button  className="list-link btn"><span><i className="fas fa-file-upload"></i></span>Logout</button>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </>
        );
    };

        export default Sidebar;


//test






