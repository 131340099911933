import React from 'react'
import { Link, Element } from 'react-scroll';
import site_logo from '../assets/img/logo.png';
import About_img from '../assets/img/about_img01.png';
import Banner_shap1 from '../assets/img/banner_shape01.png';
import Banner_shap2 from '../assets/img/banner_shape02.png';
// import Connectwallet from '../connect wallet/Connectwallwt';
import { NavLink } from 'react-router-dom';
import Footer from '../Header-Footer/Footer';

import svg1 from '../assets/img/1.svg'
import svg2 from '../assets/img/2.svg'
import svg3 from '../assets/img/3.svg'

import p1 from '../assets/img/4.png'
import p2 from '../assets/img/5.png'
import p3 from '../assets/img/6.png'
import { MLM_CONTRACT_ADDRESS } from '../Imp';

const Homepage = () => {
    return (
        <>
            <header id="header01">
                <div className="container">
                    <nav className="navbar sticky-top navbar-expand-md">
                        <div className="container px-0">
                            <a className="navbar-brand" ><img src={site_logo} alt="logo" className="logo" /></a>
                            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fas fa-bars"></i>
                            </button> */}
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <i className="fas fa-bars"></i>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto w-100 justify-content-end navbar_list">
                                    <li className="nav-item active">
                                        <Link className="nav-link mukta-regular w" to="section1" smooth={true} duration={300}>Home </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mukta-regular w" to="section2" smooth={true} duration={300}>About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mukta-regular w" to="section3" smooth={true} duration={300}>Process</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link mukta-regular w" to="section4" smooth={true} duration={300}>Partners</Link>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link mukta-regular w" to="/document" >Document</NavLink>
                                    </li>
                                    <li className="nav-item contact_li">
                                        <NavLink className=" contact mukta-regular" to="/Connectwallet"> <span className="contact_bg"> Login </span></NavLink>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <Element name="section1">
                <section className="banner">
                    <div className="container">
                        {/* <h1 className="main-heading w poppins">Join Future Of Algorithmic <span className="crypto"> Crypto</span> Trading Strategies</h1> */}
                        <h1 className="main-heading w poppins">Join Roksa Web , A Decentralized Networking Platform On Binance Smart Chain, </h1>
                        <span className='h2-text-size'>Facilitating Global Connectivity And Unlocking Opportunities In The New Economic System. </span>
                    </div>

                    <img className={Banner_shap1} src="" alt="" />
                    <img className={Banner_shap2} src="./img/banner_shape02.png" alt="" />
                </section>
            </Element>
            <Element name="section2">
                <section className="sec02">
                    <div className="container">
                        <h3 className="sec2-title w poppins"> Why should we choose Roksa Web?</h3>
                        <div className="coming-time" >
                            <div className="row">
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >Zero Risk</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >Instant transactions</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >Immutability of conditions</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >Transparency and Anonymity</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >Decentralization</p>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 cauntar">
                                    <div className="coming_box">
                                        <i className="fa-solid fa-star o"></i>
                                        <p className="coming_box_text w mt-2" >100% decentralized</p>
                                    </div>
                                </div>




                            </div>
                        </div>
                        <div className="margin-top mt-5">
                            <div className="row mt-5">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-md-center">
                                    <img src={About_img} className="about_img home-page-about" alt="" />
                                </div>
                                <div className="col-lg-6  col-md-6 col-sm-12 align-self">
                                    <h5 className="about_sub w poppins"><i className="fa-solid fa-circle me-2 bl"></i> WHO WE ARE <i className="fa-solid fa-circle ms-2 bl"></i></h5>
                                    <h2 className="about_title w poppins" >Roksa Web is a pioneering decentralized networking platform leveraging smart contracts on the Binance Smart Chain.</h2>
                                    <p className="about_text w mukta-regular">It fosters global connectivity, unlocking vast opportunities within the emergent economic landscape. By seamlessly connecting individuals worldwide, Roksa Web catalyzes the evolution of a novel financial ecosystem, offering boundless potential for innovation and growth.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Element>
            <Element name="section3">

            </Element>
            <Element name="section4">

                <section className="sec04">
                    <div className="container">
                        <h5 className="about_sub w poppins center"><i className="fa-solid fa-circle me-2 bl"></i> Process <i className="fa-solid fa-circle ms-2 bl"></i></h5>
                        <h2 className="about_title w poppins center" >How Do I Start Earning With Roksa Web</h2>
                        <div className="choose_row">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 ">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={svg1} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Create Wallet</h4>
                                        <p className="choose_text w mukta-regular">Create a Metamask or Trust Wallet. Roksa Web works with  Metamask and Trust wallet.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={svg2} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Register</h4>
                                        <p className="choose_text w mukta-regular">Register on the site.If someone invited you, use his referral link. Check that your upline is correct.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={svg3} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Activate</h4>
                                        <p className="choose_text w mukta-regular">Activate Your Account in Roksa web systems to increase your income.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>



                {/* <section className="sec04">
                    <div className="container-fluid">
                        <h5 className="about_sub w poppins center"><i className="fa-solid fa-circle me-2 bl"></i> WHY CHOOSE US <i className="fa-solid fa-circle ms-2 bl"></i></h5>
                        <h2 className="about_title w poppins center" >Why Choose Our Bigtech <br /> <span className="token"> Token </span></h2>
                        <div className="choose_row">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={choose_svg} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Lifetime free transaction</h4>
                                        <p className="choose_text w mukta-regular">Add new trending and rare artwork to your collection.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={choose_svg} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Lifetime free transaction</h4>
                                        <p className="choose_text w mukta-regular">Add new trending and rare artwork to your collection.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={choose_svg} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Lifetime free transaction</h4>
                                        <p className="choose_text w mukta-regular">Add new trending and rare artwork to your collection.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="choose-item">
                                        <div className="choose-icon-box">
                                            <img src={choose_svg} className="" alt="" />
                                        </div>
                                        <h4 className="choose_title w poppins">Lifetime free transaction</h4>
                                        <p className="choose_text w mukta-regular">Add new trending and rare artwork to your collection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </Element>
            <Element name="section4">
                <section className="sec05" id="sec005">
                    <div className="container">

                        <h2 className="about_title w poppins center" >Our Partners</h2>
                        <div className="crypto1">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-box1 icon-box01 icon-box02 center">
                                        <div className="box_icon center p-11">
                                            {/* <i className="fa-solid fa-star o"></i> */}
                                            <img src={p1} alt="" />
                                        </div>
                                        <div className="mt-3">
                                            <a href="*" className="icon_title w poppins">Meta Mask</a><br />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-box1 icon-box01 icon-box02 center">
                                        <div className="box_icon center p-12">
                                            {/* <i className="fa-solid fa-star r"></i> */}
                                            <img src={p2} alt="" />
                                        </div>
                                        <div className="mt-3">
                                            <a href="*" className="icon_title w poppins">BNB Smart Chain</a><br />
                                            {/*  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-box1 center">
                                        <div className="box_icon center p-13">
                                            {/* <i className="fa-solid fa-star bl"></i> */}
                                            <img src={p3} alt="" />
                                        </div>
                                        <div className="mt-3">
                                            <a href="*" className="icon_title w poppins">Trust Wallet</a><br />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="contact_box">
                                <div className="row ">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="contact_chart f-footer-img">
                                            <div className="center  f-img-content">
                                                <div className='text-center'>
                                                    <h3>Roksa Contract address </h3>
                                                    <p>{MLM_CONTRACT_ADDRESS}</p>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 p-0 ">
                                        <div className="contact_form">
                                            <form>
                                                <input type="text" className="input_form" placeholder="Enter Your Name" />
                                                <input type="email" className="input_form" placeholder="Enter Your Email" />
                                                <input type="tel" className="input_form" placeholder="Enter Your Mobile No." />
                                                <textarea cols="30" rows="5" className="input_form" placeholder="Enter your massage"></textarea>
                                                <span className=" contact1 mukta-regular">
                                                    <input type="submit" className="contact_bg1" value="submit" />
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Element>
            {/* <div className='footer-form'>
                <div className='container'>
                    <div className='main-row-footer'>
                        <div className="contact_form">
                            <form>
                                <div className='form-group'>
                                    <label>Wallet Address</label>
                                    <input type="text" className="input_form" placeholder="Enter Your Name" />
                                </div>
                                <div className='text-center'>
                                <span className=" contact1 contact11 mukta-regular ">
                                    <input type="submit" className="contact_bg1 contact_bg11" value="Go" />
                                </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

            <footer>
                <div className="container">
                    <div className="top_icon_main_box">
                        <div className="top_btn">
                        </div>
                        <div className="top_icon_box center">
                            <div className="top_icon">
                                <a href="#header01">
                                    <i className="fa-solid fa-arrow-up w"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 mt-3">
                            <div className="footer_col1">
                                <a className="navbar-brand" href="./index.html"><img src={site_logo} alt="logo" className="footer-logo-img" /></a>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-b t-center pp-1 mt-3">
                            {/* <h4 className="footer_title w poppins">USEFUL LINKS</h4> */}
                            <ul className="footer_list">
                                <li className="footer_list_item"><a href="#" className="footer_list_link">Contact us</a></li>
                                <li className="footer_list_item"><a href="#" className="footer_list_link">Explore</a></li>
                                <li className="footer_list_item"><a href="#" className="footer_list_link">Document</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6 align-b pp-1 t-center mt-3">
                            {/* <h4 className="footer_title w poppins">COMMUNITY</h4> */}
                            <ul className="footer_list">
                                <li className="footer_list_item"><a href="#" className="footer_list_link">About</a></li>
                                <li className="footer_list_item"><a href="#" className="footer_list_link">Process</a></li>
                                <li className="footer_list_item"><a href="#" className="footer_list_link">Partners</a></li>

                            </ul>

                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 align-b  t-center">
                            {/* <h4 className="footer_title w poppins">SUBSCRIBE NEWSLETTER</h4> */}
                            <p className="footer_text w mukta-regular mt-4">Roksa Web is a pioneering decentralized networking platform leveraging smart contracts on the Binance Smart Chain              </p>

                        </div>
                    </div>
                </div>
            </footer>



        </>
    )
}

export default Homepage