import React,{useEffect,useState} from 'react'
import Header from '../Header-Footer/Header'
import { useAppContext } from '../AppContext';
import axios from 'axios';
import qs from 'qs';
import { GrPrevious, GrNext } from "react-icons/gr";



const Income = () => {
    const {  address } = useAppContext();
    const [data, setData] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

console.log(address);
    useEffect(() => {
        callData(address)
    }, [currentPage, perPage])
    

    // const getData = async (address) => {
    //     console.log("address----",address);

        
    //     try {
    //         const response = await fetch("https://roksa.io/Api/transaction_log.php", {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': "application/json"
    //             },
    //             body: JSON.stringify({
    //                 user_address:address
    //             })
    //         }
    //         )
    //         const data = await response.json()
    //         console.log("data-------",data);
    //         setData(data.users)
    //     } catch (error) {
    //         console.log(error);
    //     }

    // }


    const callData = (address) => {

        let data = qs.stringify({
        'address': address 
        });

        let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://roksa.io/Api/incomes.php?page=${currentPage}&limit=${perPage}`,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
        };

        axios.request(config)
        .then((response) => {
        (JSON.stringify(response.data));
        console.log(response.data);
        setData(response.data);
        setTotalPages(response.data.pagination.total_pages)
        })
        .catch((error) => {
        console.log(error);
        });

}

const navigatePage = (page) => {
    setCurrentPage(page);
};
    return (
        <>
            <div class='dashboard-app'>
            <Header/>
                <div class='dashboard-content'>
                    <div class='container container-hidden'>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button partners-title1" type="button" data-bs-toggle="collapse" data-bs-target="" aria-expanded="true" aria-controls="collapseOne">
                                        Income <span class="info mx-2 w"> ID 468</span>
                                    </button>
                                </h2>
                                {/* <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Program</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">x3</option>
                                                        <option class="b" value="2">x4</option>
                                                        <option class="b" value="3">xXx</option>
                                                        <option class="b" value="4">xGold</option>
                                                        <option class="b" value="5">xQore</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label for="" class="form-lable w">Level</label>
                                                    <select name="f" class="w input-form">
                                                        <option class="b" selected="" value="0"></option>
                                                        <option class="b" value="1">1</option>
                                                        <option class="b" value="2">2</option>
                                                        <option class="b" value="3">3</option>
                                                        <option class="b" value="4">4</option>
                                                        <option class="b" value="5">5</option>
                                                    </select>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="form-lable w">Search ID / address</label>
                                                    <input type="text" class="input-form"/>
                                                </div>
                                                <div class="d-flex">
                                                    <input type="submit" value="Apply filters" class="sub-btn"/>
                                                        <input type="reset" value="Reset filters" class="rest-btn"/>
                                                        </div>
                                                </div>
                                        </form>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="partners-table-box table-responsive">
                            <div class="partners-table ">
                                <table class="table-income">
                                    <tr class="table-row">
                                        {/* <th class="table-heading w"> Type</th> */}
                                        <th class="table-heading w">Date</th>
                                        {/* <th class="table-heading w">ID</th> */}
                                        {/* <th class="table-heading w">Program</th> */}
                                        {/* <th class="table-heading w">Level</th> */}
                                        <th class="table-heading w">Wallet</th>
                                        <th class="table-heading w">USDT/ BNB profit</th>
                                    </tr>

                                    {
                                                data && data.incomes.map((item, index) => {
                                                    let level = index < 2 ? 1 : 2; // Check index to determine the level

                                                    return (
                                    <tr class="table-row">
                                        {/* <td class="table-data"> <span class="table-icon"><i class="fa-solid fa-arrow-up"></i></span> </td> */}
                                        <td class="table-data">{item.created_at}</td>
                                        {/* <td class="table-data"><span class="user-id m-0">ID 1668898</span></td> */}
                                        {/* <td class="table-data">maxQore</td> */}
                                        {/* <td class="table-data">1</td> */}
                                        <td class="table-data">{item.from}                                </td>
                                        <td class="table-data g">{item.income}</td>
                                    </tr>
)
})
}
                                </table>

                            </div>
                            {/* <button class="see-more" href="#">See More</button> */}
                            <div className='pagenation-1'>
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">

                                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                            <a className="page-link" onClick={() => navigatePage(currentPage - 1)}>
                                                                <GrPrevious />
                                                            </a>
                                                        </li>



                                                        {Array.from({ length: Math.min(totalPages, 3) }, (_, index) => {
                                                            let page = 1;
                                                            if (currentPage === totalPages) {
                                                                page = totalPages - 2 + index;
                                                            } else {
                                                                page = Math.min(Math.max(currentPage - 1 + index, index + 1), totalPages);
                                                            }
                                                            return (
                                                                <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                                                                    <a className="page-link" onClick={() => navigatePage(page)}>{page}</a>
                                                                </li>
                                                            );
                                                        })}

                                                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                            <a className="page-link" onClick={() => navigatePage(currentPage + 1)}>
                                                                <GrNext />
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </nav>
                                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Income